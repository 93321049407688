import React, { memo } from 'react';
import Translations from '@app/translations/globalTranslations';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import {
  Container,
  TitleSection,
  SwipperSection,
  SwiperOutsideContainer,
  SwiperElement,
} from './CurrentOrders.style';
import { Swiper } from '@app/shared/components/Swiper';
import { useCurrentOrders } from './useCurrentOrders';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { CurrentOrdersProps, DependenciesProps } from './CurrentOrders.type';
import type { FC } from 'react';
/**
 * Components
 */
import { Badge } from '@app/shared/components/Badge';
import { OrderItem } from '@app/orders/presentation/components/OrderItem';

export const CurrentOrders: FC<CurrentOrdersProps> = WithDependencies(
  memo(({ orders, scrollButtons, dependencies }) => {
    const { swipeSectionRef, swipeHeight, onClickCard, containerRef, showHelpCenter } =
      useCurrentOrders({ dependencies });
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();

    return (
      <Container ref={containerRef}>
        <TitleSection bBottom={swipeHeight <= 0}>
          <Typography
            token="font-subtitle-highcontrast-sentence-large"
            color="text-color-action-enabled-loud"
          >
            {formatMessage(Translations['general.status.pending'])}
          </Typography>
          <Badge count={orders.length} type="invert" />
        </TitleSection>
        <SwiperOutsideContainer display={swipeHeight > 0} fullWidth={orders.length <= 1}>
          <Swiper withScrollButtons={scrollButtons}>
            <SwipperSection ref={swipeSectionRef} fullWidth={orders.length <= 1}>
              {orders.map((element, index) => {
                return (
                  <SwiperElement
                    key={element?.orderId}
                    marginLeft={index === 0 ? '16px' : '0px'}
                    marginRight={index === orders.length - 1 ? '16px' : '0px'}
                  >
                    <OrderItem
                      vendor={element?.vendor?.name}
                      vendorImage={element?.vendor?.logo}
                      orderId={element?.orderId}
                      items={element?.items}
                      status={element?.status}
                      registeredDate={element?.date}
                      dropOff={element?.estimatedDropOff}
                      orderAmount={element?.orderAmount}
                      currency={element?.currency}
                      country={element?.countryCode ? element.countryCode.toLowerCase() : ''}
                      businessType={element?.vendor.businessType}
                      onClickCard={() => onClickCard(element?.orderId, element?.countryCode)}
                      rateEnabled={false}
                      showHelpCenter={showHelpCenter}
                      showActions={false}
                    />
                  </SwiperElement>
                );
              })}
            </SwipperSection>
          </Swiper>
        </SwiperOutsideContainer>
      </Container>
    );
  }),
);
