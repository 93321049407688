import React from 'react';
import PropTypes from 'prop-types';
import Client from '@app/shared/utils/query_client';
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import { LocalStyle } from '@app/shared/components/LocalStyle';
import { RouterApp } from '@app/shared/components/Router';
import { createIntlProvider, getMessages } from '@app/shared/contexts/IntlProvider';
import { SentryProvider } from '@app/shared/contexts/SentryProvider';
import { FWFProvider } from '@app/shared/contexts/FWFProvider';
import { usePublicEnv, PublicEnvProvider, getPublicEnv } from '@app/shared/contexts/PublicEnv';
import { QueryClientProvider } from 'react-query';
import { TrackerProvider } from '@app/shared/contexts/TrackerProvider';
import { LinkProvider } from '@app/shared/contexts/LinkProvider';
import { AuthProvider } from '@app/shared/contexts/AuthProvider';
import { AndroidEventProvider } from '@app/shared/contexts/AndroidEventProvider';
import { SentrySSR } from '@app/shared/contexts/SentrySSR';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

const Root = ({
  context,
  messages,
  name,
  tracker,
  fwf,
  trackerConfig,
  fwfConfig,
  sentryConfig,
}) => (
  <SentrySSR
    sentryDSN={sentryConfig?.dsn}
    environment={ssrProps?.context?.environment}
    enabled={sentryConfig?.isEnabled}
    isServer={ssrProps?.isServer ? true : false}
  >
    <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
      <AuthProvider>
        <SentryProvider isServer={ssrProps?.isServer ? true : false}>
          <ThemeProvider theme={fenixTheme}>
            <>
              <FenixFonts />
              <LocalStyle />
              <IntlProvider initialMessages={messages}>
                <AndroidEventProvider>
                  <TrackerProvider tracker={tracker} config={trackerConfig}>
                    <FWFProvider fwf={fwf} config={fwfConfig}>
                      <LinkProvider>
                        <QueryClientProvider client={Client}>
                          <RouterApp />
                        </QueryClientProvider>
                      </LinkProvider>
                    </FWFProvider>
                  </TrackerProvider>
                </AndroidEventProvider>
              </IntlProvider>
            </>
          </ThemeProvider>
        </SentryProvider>
      </AuthProvider>
    </PublicEnvProvider>
  </SentrySSR>
);

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.object,
  messages: PropTypes.shape({}),
  name: PropTypes.string,
  tracker: PropTypes.object,
  trackerConfig: PropTypes.object,
  fwfConfig: PropTypes.object,
  sentryConfig: PropTypes.object,
};

export default Root;
